<template>
  <m-header />
  <div class="container mt-5 pt-5 flex-shrink-0">
    <h1 v-if="title" class="mb-5">{{ title }}</h1>
    <slot />
  </div>
  <m-footer />
</template>

<script>
import MHeader from '@/components/navigation/MHeader.vue';
import MFooter from '@/components/navigation/MFooter.vue';

export default {
  name: 'Page',
  props: {
    title: String,
  },
  components: { MHeader, MFooter },
};
</script>

<style scoped>
</style>
