<template>
  <router-view></router-view>
</template>

<script>

export default {
  name: 'App',
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.multiselect-input {
  border-color: #ced4da;
  border-radius: 0.25rem;
}

.multiselect-option.is-selected {
  background-color: #0d6efd;
}

.multiselect-option.is-selected.is-pointed {
  background-color: #0c62e2;
}
</style>
