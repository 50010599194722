<template>
  <page>
    <hgroup  class="mb-5 d-flex justify-content-between">
      <h1>Lista punktów</h1>
      <m-button
        class="btn btn-success btn-lg"
        name="Zgłoś nowy punkt"
        emitName="addPoint"
        icon="bi-plus-circle"
        @addPointButton="changeShowModal"
      />
    </hgroup>
    <points-table
      @showMessage="changeShowMessage"
    />
    <teleport
      v-if="showAddPointModal"
      to="#app"
    >
      <add-point-modal
        @closeModalButton="changeShowModal"
        @showMessage="changeShowMessage"
      />
    </teleport>
    <teleport
      v-if="showMessageModal"
      to="#app"
    >
      <message-modal
        :message="modalMessage"
        @showMessage="changeShowMessage"
      />
    </teleport>
  </page>
</template>

<script>
import { ref } from 'vue';

import Page from '@/layouts/Page.vue';
import PointsTable from '@/components/table/PointsTable.vue';
import MButton from '@/components/common/MButton.vue';
import AddPointModal from '@/components/modal/AddPointModal.vue';
import MessageModal from '@/components/modal/MessageModal.vue';

export default {
  name: 'PointsList',
  components: {
    MessageModal,
    AddPointModal,
    MButton,
    PointsTable,
    Page,
  },
  setup() {
    const showAddPointModal = ref(false);
    const showMessageModal = ref(false);

    const modalMessage = ref('');

    function changeShowModal() {
      showAddPointModal.value = !showAddPointModal.value;
    }

    function changeShowMessage(e) {
      if (e) modalMessage.value = e;
      else modalMessage.value = '';
      showMessageModal.value = !showMessageModal.value;
    }

    return {
      changeShowModal,
      changeShowMessage,
      modalMessage,
      showMessageModal,
      showAddPointModal,
    };
  },
};
</script>

<style scoped>

</style>
