<template>
  <div class="d-flex w-100 mb-2 mt-3">
    <span class="me-1">
      {{ index + 1 }}.
    </span>
    <b>
      {{ value.name }}
    </b>
    <div class="d-flex flex-grow-1"></div>
    <m-button
      class="btn btn-sm btn-outline-secondary me-2"
      emitName="show-details"
      :name="showDetails ? 'Schowaj szczegóły' : 'Zobacz szczegóły'"
      icon="bi-eye"
      @showDetailsButton="$emit('show-details', value.id)"
    />
    <m-button
      class="btn btn-sm btn-outline-primary"
      emitName="edit"
      name="Edytuj"
      icon="bi-pen"
      @editButton="closeEdit"
    />
  </div>
  <div v-if="showDetails">
    <point-details
      :point-id="value.id"
      :filters="filters"
      @closeDetails="$emit('show-details', value.id)"
      @message="$emit('show-message', $event)"
    />
  </div>
  <div v-if="showEdit">
    <div class="card">
      <div class="card-body">
        <edit-point-form
          :point-id="value.id"
          @closeEdit="closeEdit"
        />
      </div>
    </div>
  </div>

</template>

<script>
import MButton from '@/components/common/MButton.vue';
import PointDetails from '@/components/layout/PointDetails.vue';
import EditPointForm from '@/components/form/EditPointForm.vue';

export default {
  name: 'PointsTableRow',
  components: { EditPointForm, PointDetails, MButton },
  emits: ['show-details', 'show-edit', 'show-message'],
  props: {
    index: Number,
    filters: Object,
    value: Object,
    showEdit: Boolean,
    showDetails: Boolean,
  },
  setup(props, { emit }) {
    function closeEdit(e) {
      emit('show-edit', { valueId: props.value.id, ...e });
    }

    return {
      closeEdit,
    };
  },
};
</script>

<style scoped>
</style>
