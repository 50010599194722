<template>
  <div class="mt-4 pt-4 border-top">
    <h5>Dystrybucja</h5>
    <div
      v-for="file in files"
      :key="file.name"
    >
      <upload-file-box
        :label="file.label"
        :type="file.name"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<script>
import UploadFileBox from '@/components/layout/UploadFileBox.vue';

export default {
  name: 'DistributionUploadFiles',
  components: { UploadFileBox },
  setup() {
    const files = [
      {
        name: 'agreement',
        label: 'Umowa',
      },
      {
        name: 'invoice',
        label: 'Faktura',
      },
      {
        name: 'comments',
        label: 'Uwagi',
      },
    ];

    return {
      files,
    };
  },
};
</script>

<style scoped>

</style>
