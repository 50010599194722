<template>
  <div class="starry-bg">
  <div class="d-flex align-items-center justify-content-center vh-100 container">
    <div class="card d-flex login-box border-dark">
      <div class="d-flex w-100 logo mx-0 mb-3 pt-4 p-3 align-content-center justify-content-center">
      <img class="w-50" :src="require('@/assets/logo.png')" alt="Voltra">
      </div>
      <div class="card-body pb-3 px-5">
        <h5 class="card-title text-center mb-4">Logowanie</h5>
        <login-form />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import LoginForm from '@/components/form/LoginForm.vue';

export default {
  name: 'Login',
  components: { LoginForm },

  setup() {
  },
};
</script>

<style scoped>
.login-box {
  min-width: 300px;
  width: 25rem;
  overflow: hidden;
  box-shadow:
    0 1.8px 2.2px rgba(0, 0, 0, 0.034),
    0 4.7px 5.3px rgba(0, 0, 0, 0.048),
    0 8.5px 10px rgba(0, 0, 0, 0.06),
    0 16.3px 17.9px rgba(0, 0, 0, 0.072);
}
.logo {
  background-color: #2f2f2f;
}

.starry-bg {
  background-image: url("~@/assets/header_bg.jpeg");
  background-size: cover;
}
</style>
