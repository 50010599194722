<template>
  <footer class="footer mt-auto py-0">
    <div class="border-top d-flex justify-content-between mt-5 py-4 container">
     Copyright Voltra 2021
    </div>
  </footer>
</template>

<script>
export default {
  name: 'MFooter',
};
</script>

<style scoped>

</style>
