<template>
  <form>
    <div>
      <MInput
        class="mb-3"
        v-model="password"
        placeholder="Hasło"
        required
        type="password"
      />
      <p
        class="mt-3 text-danger small"
        v-if="msg.password"
      >
        {{ msg.password }}
      </p>
    </div>
    <div>
      <MInput
        v-model="repeatPassword"
        placeholder="Powtórz hasło"
        required
        type="password"
      />
      <p
        class="mt-3 text-danger small"
        v-if="msg.repeatPassword"
      >
        {{ msg.repeatPassword }}
      </p>
      <p
        class="mt-3 text-danger small"
        v-if="!msg.repeatPassword && !msg.password && msg.samePassword"
      >
        {{ msg.samePassword }}
      </p>
      <p
        class="mt-3 text-danger small"
        v-if="statusCode && statusCode!==200"
      >
        Problem w komunikacji z serwerem, spróbuj ponownie później.
      </p>
    </div>
    <MButton
      class="btn btn-primary mt-3 w-100"
      emitName="changePassword"
      name="Wyślij"
      @changePasswordButton="sendChangePasswordRequest"
    />
  </form>
</template>

<script>
import { reactive, ref } from 'vue';

import MInput from '@/components/common/MInput.vue';
import MButton from '@/components/common/MButton.vue';
import axios from 'axios';
import { urlBuilder } from '@/plugins/helpers';

export default {
  name: 'ChangePasswordForm',
  components: { MButton, MInput },
  props: {
    hash: {
      type: String,
      default: '',
    },
  },
  setup(props, { emit }) {
    const password = ref('');
    const repeatPassword = ref('');
    const statusCode = ref();

    const msg = reactive({
      password: '',
      repeatPassword: '',
      samePassword: '',
    });

    function validationForm() {
      msg.password = '';
      msg.repeatPassword = '';
      statusCode.value = null;

      if (!password.value) {
        msg.password = 'Pole "hasło" nie może być puste.';
      }
      if (!repeatPassword.value) {
        msg.repeatPassword = 'Pole "powtórz hasło" nie może być puste.';
      }
      if (password.value !== repeatPassword.value) {
        msg.samePassword = 'Wpisane hasła są różne.';
      }
      return (!msg.repeatPassword && !msg.password) && (password.value === repeatPassword.value);
    }

    async function changePassword(payload) {
      return axios.post(urlBuilder(`/reset_password/${props.hash}/`), payload);
    }

    async function sendChangePasswordRequest() {
      if (validationForm()) {
        const payload = {
          new_password: password.value,
          password_confirmation: repeatPassword.value,
        };
        statusCode.value = (await changePassword(payload)).status;
      }
      if (statusCode.value === 200) {
        emit('password-changed');
      }
    }

    function enterButtonListener(e) {
      if (e.key === 'Enter') sendChangePasswordRequest();
    }

    window.addEventListener('keydown', enterButtonListener);

    return {
      msg,
      password,
      sendChangePasswordRequest,
      statusCode,
      repeatPassword,
    };
  },
};
</script>

<style scoped>

</style>
