<template>
  <div class="d-flex">
    <template
      v-for="(tag, index) in tags"
      :key="tag.id"
    >
      <m-chip
        :value="tag.value"
        :label="tag.label"
        @chip="changeValue($event, index)"
        :color="'bg-primary'"
      />
    </template>
    <m-chip
      :value="isEmpty"
      :label="'Bez tagów'"
      @chip="changeIsEmpty($event)"
      :color="'bg-success'"
    />
  </div>
</template>

<script>

import { onMounted, ref } from 'vue';
import axios from 'axios';

import { urlBuilder } from '@/plugins/helpers';
import MChip from '@/components/common/MChip.vue';

export default {
  name: 'TagsFilter',
  components: { MChip },
  emits: ['change-filters'],
  setup(props, { emit }) {
    const tags = ref([]);
    const isEmpty = ref(true);

    async function getTags() {
      const data = (await axios.get(urlBuilder('/tags/'))).data.results;
      tags.value = data.map((elem) => ({
        label: elem.name,
        id: elem.id,
        value: true,
      }));
    }

    const visibleTags = ref([]);

    function showTags() {
      visibleTags.value = tags.value.reduce((filtered, tag) => {
        if (tag.value) filtered.push(tag.id);
        return filtered;
      }, []);
    }

    function emitChangeFilters() {
      emit('change-filters', {
        visibleTags: visibleTags.value,
        isEmpty: isEmpty.value,
      });
    }

    onMounted(async () => {
      await getTags();
      showTags();
      emitChangeFilters();
    });

    function changeValue(e, index) {
      tags.value[index].value = !tags.value[index].value;
      showTags();
      emitChangeFilters();
    }

    function changeIsEmpty(e) {
      isEmpty.value = e;
      emitChangeFilters();
    }

    return {
      changeIsEmpty,
      changeValue,
      isEmpty,
      tags,
    };
  },
};
</script>

<style scoped>

</style>
