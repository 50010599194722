<template>
  <div class="d-flex flex-row align-items-center justify-content-between">
    <tags-filter
      @changeFilters="changeFilters"
    />
    <month-filter />
    </div>
  <div
    v-for="(value, index) in visiblePoints"
    :key="index"
  >
    <points-table-row
      :index="index + (currentPage - 1) * pageSize"
      :filters="filters"
      :value="value"
      :showDetails="showDetailsIndexes[value.id] === true"
      :show-edit="showEditIndex === value.id"
      @showDetails="setDetailsIndex"
      @showEdit="setEditIndex"
      @showMessage="$emit('show-message', $event)"
    />
  </div>
  <total-cost :data="summary"/>
  <div class="align-center text-center w-100 d-inline-block">
    <div
        class="btn btn-outline-secondary"
        v-for="(value, index) in links"
        :key="index"
        @click="getPage(value)"
    >
      {{ index + 1 }}
    </div>
  </div>
</template>

<script>
import {
  ref, reactive, computed, watch,
} from 'vue';
import store from '@/store';
import axios from 'axios';

import { urlBuilder } from '@/plugins/helpers';

import PointsTableRow from '@/components/table/rows/PointsTableRow.vue';
import TagsFilter from '@/layouts/TagsFilter.vue';
import MonthFilter from '@/layouts/MonthFilter.vue';
import TotalCost from '@/components/layout/TotalCost.vue';

export default {
  name: 'PointsTable',
  components: {
    TotalCost, MonthFilter, TagsFilter, PointsTableRow,
  },
  emits: ['show-message'],
  setup(props, { emit }) {
    const points = ref([]);

    const showDetailsIndexes = ref([]);
    const showEditIndex = ref(null);

    const summary = reactive({
      cost: 0,
      usage: 0,
    });

    const dateFrom = computed(() => store.getters.dateFromFilter);
    const dateTo = computed(() => (
      store.getters.dateToFilter ? store.getters.dateToFilter.add(1, 'month') : null));

    function setDetailsIndex(e) {
      if (showDetailsIndexes.value[e] !== true) {
        showDetailsIndexes.value[e] = true;
      } else {
        showDetailsIndexes.value[e] = false;
      }
      showEditIndex.value = null;
    }

    const visiblePoints = ref([]);

    const filters = reactive({
      empty_tags: true,
    });

    const currentPage = ref(1);
    const totalPages = ref(1);
    const pageSize = ref(100);
    const links = ref(null);

    async function getPage(pageUrl) {
      const pointsData = (await axios.get(pageUrl)).data;
      visiblePoints.value = pointsData.results;
      summary.cost = pointsData.total_costs.costs;
      summary.usage = pointsData.total_costs.usage;
      currentPage.value = pointsData.page;
      totalPages.value = pointsData.total_pages;
      links.value = pointsData.links;
      pageSize.value = pointsData.page_size;
    }

    function getFilteredList() {
      if (dateFrom.value) filters.costs_date_after = dateFrom.value.format('YYYY-M-DD');
      else delete filters.costs_date_after;
      if (dateTo.value) filters.costs_date_before = dateTo.value.format('YYYY-M-DD');
      else delete filters.costs_date_before;
      if (!filters.tags && !filters.empty_tags) {
        visiblePoints.value = [];
        summary.cost = 0;
        summary.usage = 0;
      } else {
        filters.page = currentPage;
        getPage(urlBuilder('/points/', filters));
      }
    }

    function changeFilters(e) {
      currentPage.value = 1;
      if (e.isEmpty) filters.empty_tags = e.isEmpty;
      else delete filters.empty_tags;
      filters.tags = e.visibleTags.join(',');
      getFilteredList();
    }

    watch(() => (dateFrom.value || dateTo.value), () => {
      getFilteredList();
    });

    async function setEditIndex(e) {
      if (showEditIndex.value !== e.valueId) {
        showEditIndex.value = e.valueId;
      } else {
        showEditIndex.value = null;
      }
      if (e.message) {
        emit('show-message', e.message);
      }
      showDetailsIndexes.value[e.valueId] = false;
      await getFilteredList();
    }

    return {
      changeFilters,
      currentPage,
      filters,
      pageSize,
      points,
      getPage,
      setDetailsIndex,
      setEditIndex,
      showDetailsIndexes,
      showEditIndex,
      summary,
      links,
      totalPages,
      visiblePoints,
    };
  },
};
</script>

<style scoped>

</style>
