<template>
  <page title="Sprawdź ile zyskujesz">
    <profit-box />
  </page>
</template>

<script>
import Page from '@/layouts/Page.vue';
import ProfitBox from '@/layouts/ProfitBox.vue';

export default {
  name: 'Profits',
  components: { ProfitBox, Page },
};
</script>

<style scoped>

</style>
