import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

import jwtDecode from 'jwt-decode';

const dataState = createPersistedState({
  paths: [
    'accessToken',
    'refreshToken',
  ],
});

const store = createStore({
  plugins: [dataState],

  state() {
    return {
      accessToken: '',
      refreshToken: '',
      dateFromFilter: null,
      dateToFilter: null,
      isSuperuser: true,
    };
  },

  getters: {
    accessToken: (state) => state.accessToken,
    refreshToken: (state) => state.refreshToken,
    dateFromFilter: (state) => state.dateFromFilter,
    dateToFilter: (state) => state.dateToFilter,
    isSuperuser: (state) => state.isSuperuser,
  },

  mutations: {
    saveAccessToken(state, token) {
      state.accessToken = token;
      const decoded = jwtDecode(token);
      state.isSuperuser = decoded.is_superuser;
    },
    saveRefreshToken(state, token) {
      state.refreshToken = token;
    },
    deleteAccessToken(state) {
      state.accessToken = '';
    },
    deleteRefreshToken(state) {
      state.refreshToken = '';
    },
    SAVE_DATE_FILTER_FROM: (state, value) => { state.dateFromFilter = value; },
    SAVE_DATE_FILTER_TO: (state, value) => { state.dateToFilter = value; },
    SET_IS_SUPERUSER: (state) => { state.isSuperuser = jwtDecode(state.accessToken).is_superuser; },
    RESTART_IS_SUPERUSER: (state) => { state.isSuperuser = true; },
  },

  actions: {
    saveDateFilterFrom: ({ commit }, value) => commit('SAVE_DATE_FILTER_FROM', value),
    saveDateFilterTo: ({ commit }, value) => commit('SAVE_DATE_FILTER_TO', value),
    setIsSuperuser: ({ commit }) => commit('SET_IS_SUPERUSER'),
    restartIsSuperuser: ({ commit }) => commit('RESTART_IS_SUPERUSER'),
  },
});

export default store;
