<template>
  <div class="row d-flex flex-grow-1 align-items-center">
    <div class="col-sm-3" v-if="label">
      <label class="fw-bold">{{ label }}</label>
    </div>
    <div :class="label ? 'col-sm-9' : 'col-sm-12'">
      <date-picker
        v-model.string="selectedDate"
        @update:modelValue="newDate"
        :masks="masks"
      >
        <template v-slot="{ inputEvents }">
          <m-input
            :model-value="selectedDate"
            v-on="inputEvents"
          />
        </template>
      </date-picker>
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';

import { DatePicker } from 'v-calendar';
import 'v-calendar/dist/style.css';
import MInput from '@/components/common/MInput.vue';

export default {
  name: 'MDatePicker',
  components: { MInput, DatePicker },
  emits: ['update:modelValue'],
  props: {
    label: String,
    modelValue: String,
  },
  setup(props, { emit }) {
    const selectedDate = ref('');

    watch(() => props.modelValue, () => {
      selectedDate.value = props.modelValue;
    });

    function newDate() {
      emit('update:modelValue', selectedDate.value);
    }

    const masks = ref({
      modelValue: 'YYYY-MM-DD',
    });

    return {
      masks,
      newDate,
      selectedDate,
    };
  },
};
</script>

<style scoped>

</style>
