<template>
  <m-modal title="Zgłoszenie nowego punktu poboru energii">
    <edit-point-form v-bind="$attrs" />
  </m-modal>
</template>

<script>
import EditPointForm from '@/components/form/EditPointForm.vue';
import MModal from '@/components/common/MModal.vue';

export default {
  name: '',
  components: { MModal, EditPointForm },
};
</script>

<style scoped>
</style>
