import axios from 'axios';

import store from '@/store/index';
import router from '@/router/index';

import { urlBuilder } from '@/plugins/helpers';

export default async function login(payload) {
  let response = {};
  let statusCode = null;

  try {
    response = (await axios.post(urlBuilder('/token/'), payload));
  } catch (error) {
    statusCode = error.response.status;
  }

  if (!statusCode) {
    store.commit('saveAccessToken', response.data.access);
    store.commit('saveRefreshToken', response.data.refresh);
    statusCode = response.status;
  }

  if (store.getters.accessToken && store.getters.refreshToken) {
    await router.push('/strona-glowna');
  }
  return statusCode;
}

export async function logout() {
  store.commit('deleteAccessToken');
  store.commit('deleteRefreshToken');
  store.dispatch('restartIsSuperuser');
  await router.push('/');
}
