<template>
  <page title="Wzory">
    <download-files-box />
  </page>
</template>
<script>
import Page from '@/layouts/Page.vue';
import DownloadFilesBox from '@/layouts/DownloadFilesBox.vue';

export default {
  name: 'Patterns',
  components: { DownloadFilesBox, Page },
};
</script>

<style scoped>

</style>
