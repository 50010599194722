<template>
  <button
    v-if="!link"
    class="btn"
    @click="$emit(`${emitName}-button`)"
    type="button"
  >
    {{ name }}
    <i :class="icon" class="btn-icon" v-if="icon" />
  </button>
  <a
    v-else
    class="btn"
    @click="$emit(`${emitName}-link`)"
    :href="link"
    :download="fileName"
  >
    {{ name }}
    <i :class="icon" class="btn-icon" v-if="icon" />
  </a>
</template>

<script>
export default {
  name: 'MButton',
  props: {
    name: String,
    emitName: {
      type: String,
      default: 'simple',
    },
    icon: {
      type: String,
      default: null,
    },
    link: {
      type: String,
      default: null,
    },
    fileName: {
      type: String,
      default: null,
    },
  },
};
</script>

<style scoped>
.btn-icon {
  margin-left: 0.2em;
}
</style>
