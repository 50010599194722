<template>
  <m-modal v-bind="$attrs">
    <div class="text-center">
      <div class="row">
        <div class="col-lg-6 text-center">
          <span>
            Od:
          </span>
          <m-year-picker
            name="from"
            :year="yearFrom"
            @selectYear="changeYear"
          />
          <m-month-picker
            name="from"
            :month="monthFrom"
            @selectMonth="changeMonth"
          />
        </div>
        <div class="col-lg-6 text-center">
          <span>
            Do:
          </span>
          <m-year-picker
            name="to"
            :year="yearTo"
            @selectYear="changeYear"
          />
          <m-month-picker
            name="to"
            :month="monthTo"
            @selectMonth="changeMonth"
          />
        </div>
      </div>
      <m-button
        class="btn btn-primary btn-lg mt-3"
        name="Zapisz"
        @click="saveData"
      />
    </div>
  </m-modal>
</template>

<script>
import { onBeforeMount, ref } from 'vue';
import dayjs from 'dayjs';

import store from '@/store';

import MMonthPicker from '@/components/common/MMonthPicker.vue';
import MYearPicker from '@/components/common/MYearPicker.vue';
import MModal from '@/components/common/MModal.vue';
import MButton from '@/components/common/MButton.vue';

export default {
  name: 'MonthYearFilterModal',
  components: {
    MButton, MModal, MMonthPicker, MYearPicker,
  },

  setup(props, { emit }) {
    const yearFrom = ref(null);
    const yearTo = ref(null);
    const monthFrom = ref(null);
    const monthTo = ref(null);

    onBeforeMount(() => {
      const dateFrom = store.getters.dateFromFilter;
      const dateTo = store.getters.dateToFilter;
      if (store.getters.dateFromFilter) {
        yearFrom.value = String(dateFrom.year());
        monthFrom.value = dateFrom.month() + 1;
      }
      if (store.getters.dateToFilter) {
        yearTo.value = String(dateTo.year());
        monthTo.value = dateTo.month() + 1;
      }
    });

    function changeYear(e) {
      if (e.name === 'from') yearFrom.value = e.year;
      else if (e.name === 'to') yearTo.value = e.year;
    }

    function changeMonth(e) {
      if (e.name === 'from' && e.isSelectedMonth) monthFrom.value = e.month + 1;
      else if (e.name === 'from') monthFrom.value = null;
      else if (e.name === 'to' && e.isSelectedMonth) monthTo.value = e.month + 1;
      else if (e.name === 'to') monthTo.value = null;
    }

    function saveData() {
      let from = null;
      let to = null;
      if (yearFrom.value && monthFrom.value) {
        from = dayjs(`${yearFrom.value}-${monthFrom.value}-01`);
      }
      if (yearTo.value && monthTo.value) {
        to = dayjs(`${yearTo.value}-${monthTo.value}-01`);
      }
      if (from && to && from.isAfter(to)) {
        from = to;
      }
      store.dispatch('saveDateFilterFrom', from);
      store.dispatch('saveDateFilterTo', to);
      emit('close-modal-button');
    }

    return {
      changeMonth,
      changeYear,
      monthFrom,
      monthTo,
      saveData,
      yearFrom,
      yearTo,
    };
  },
};
</script>

<style scoped>

</style>
