<template>
  <div class="d-flex align-items-center">
    <label class="me-2">Zakres&nbsp;czasowy:</label>
    <m-input
      id="month-filter"
      placeholder="Zakres"
      :modelValue="inputValue"
      @focus="changeMonthYearFilterState(true)"
    />
  </div>
  <teleport
    v-if="showMonthYearFilterModal"
    to="#app"
  >
    <month-year-filter-modal
      @closeModalButton="changeMonthYearFilterState(false)"
    />
  </teleport>
</template>

<script>
import { ref, computed } from 'vue';

import store from '@/store';

import MInput from '@/components/common/MInput.vue';
import MonthYearFilterModal from '@/components/modal/MonthYearFilterModal.vue';

export default {
  name: 'MonthFilter',
  components: {
    MonthYearFilterModal,
    MInput,
  },
  emits: ['range-changed'],

  setup(props, { emit }) {
    const showMonthYearFilterModal = ref(false);

    function changeMonthYearFilterState(value) {
      if (value) document.getElementById('month-filter').querySelector('input').blur();
      showMonthYearFilterModal.value = value;
      if (!value) emit('range-changed');
    }

    const inputValue = computed(() => {
      const from = store.getters.dateFromFilter ? store.getters.dateFromFilter.format('MM/YYYY') : '';
      const to = store.getters.dateToFilter ? store.getters.dateToFilter.format('MM/YYYY') : '';
      let value = null;
      if (from || to) value = `${from} - ${to}`;
      return value;
    });

    return {
      changeMonthYearFilterState,
      inputValue,
      showMonthYearFilterModal,
    };
  },
};
</script>

<style scoped>
</style>
