<template>
  <div class="starry-bg">
  <div class="d-flex align-items-center justify-content-center vh-100 container">
    <div class="card d-flex login-box border-dark">
      <div class="d-flex w-100 logo mx-0 mb-3 pt-4 p-3 align-content-center justify-content-center">
        <img class="w-50" :src="require('@/assets/logo.png')" alt="Voltra">
      </div>
      <div class="card-body pb-3 px-5">
        <div v-if='passwordChanged'>
          Hasło zostało zmienione.
          <MButton name="Wróć do strony logowania" @click="router.push('/')"/>
        </div>
        <div v-else>
          <change-password-form :hash="newHash" @passwordChanged='passwordChanged=true'/>
        </div>
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import axios from 'axios';
import { urlBuilder } from '@/plugins/helpers';
import { useRouter } from 'vue-router';
import ChangePasswordForm from '@/components/form/ChangePasswordForm.vue';
import { onMounted, ref } from 'vue';
import MButton from '@/components/common/MButton.vue';

export default {
  components: { MButton, ChangePasswordForm },
  props: ['hash'],
  setup(props) {
    const router = useRouter();
    const newHash = ref('');
    const passwordChanged = ref(false);
    async function validateHash() {
      const payload = {
        hash: props.hash,
      };
      try {
        return (await axios.post(urlBuilder('/reset_password/validate/'), payload)).data.hash;
      } catch {
        await router.push('/');
        return undefined;
      }
    }

    onMounted(async () => {
      newHash.value = await validateHash();
    });

    return {
      newHash,
      passwordChanged,
      router,
    };
  },
};
</script>

<style scoped>
.login-box {
  min-width: 300px;
  width: 25rem;
  overflow: hidden;
  box-shadow:
    0 1.8px 2.2px rgba(0, 0, 0, 0.034),
    0 4.7px 5.3px rgba(0, 0, 0, 0.048),
    0 8.5px 10px rgba(0, 0, 0, 0.06),
    0 16.3px 17.9px rgba(0, 0, 0, 0.072);
}
.logo {
  background-color: #2f2f2f;
}

.starry-bg {
  background-image: url("~@/assets/header_bg.jpeg");
  background-size: cover;
}
</style>
