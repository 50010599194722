import { createApp } from 'vue';
import store from '@/store/index';
import axios from 'axios';
import axiosSetup from '@/plugins/axios';
import VueAxios from 'vue-axios';
import App from './App.vue';
import router from './router';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-icons/font/bootstrap-icons.css';

axiosSetup();

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .mount('#app');
