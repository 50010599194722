<template>
  <div
    class="my-2"
    v-for="formRow in formRows"
    :key="formRow.name"
  >
    <m-input
      v-if="!formRow.type"
      :label="formRow.label"
      v-model="formRow.value"
    />
    <m-date-picker
      v-if="formRow.type === 'date'"
      :label="formRow.label"
      v-model="formRow.value"
    />
  </div>

  <m-multiselect
    label="Tagi"
    class="my-1"
    :data="tags"
    :model-value="selectedTags"
    @update:modelValue="selectTags"
  />

  <div v-if="errorMessage">
    <p v-for="error in errorMessage" :key="error" class="mt-3 text-danger small">
      {{ error }}
    </p>
  </div>

  <div class="d-flex w-100 justify-content-end mb-2">
    <MButton
      class="btn btn-primary mt-3"
      :emitName="buttonProps.emitName"
      :name="buttonProps.name"
      icon="bi-arrow-up"
      @click="sendData"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { onMounted, ref, reactive } from 'vue';

import { urlBuilder } from '@/plugins/helpers';

import MInput from '@/components/common/MInput.vue';
import MButton from '@/components/common/MButton.vue';
import MMultiselect from '@/components/common/MMultiselect.vue';
import MDatePicker from '@/components/common/MDatePicker.vue';

export default {
  name: 'EditPointForm',
  components: {
    MMultiselect, MButton, MInput, MDatePicker,
  },
  emits: ['close-modal-button', 'close-edit', 'show-message'],
  props: {
    pointId: {
      type: Number,
      default: null,
    },
  },
  setup(props, { emit }) {
    const formRows = ref([
      {
        name: 'city',
        label: 'Miasto punktu',
        value: '',
      },
      {
        name: 'post',
        label: 'Poczta punktu',
        value: '',
      },
      {
        name: 'street',
        label: 'Ulica punktu',
        value: '',
      },
      {
        name: 'street_number',
        label: 'Numer miejsca/domu punktu',
        value: '',
      },
      {
        name: 'zip_code',
        label: 'Kod pocztowy punktu',
        value: '',
      },
      {
        name: 'name',
        label: 'Nazwa punktu poboru',
        value: '',
      },
      {
        name: 'ppe_number',
        label: 'Numer PPE',
        value: '',
      },
      {
        name: 'osd_number',
        label: 'Numer ewidencyjny OSD',
        value: '',
      },
      {
        name: 'counter_number',
        label: 'Numer licznika',
        value: '',
      },
      {
        name: 'tariff',
        label: 'Obecna taryfa',
        value: '',
      },
      {
        name: 'power',
        label: 'Moc umowna',
        value: '',
      },
      {
        name: 'osd_next',
        label: 'Numer lokalnego OSD',
        value: '',
      },
      {
        name: 'seller',
        label: 'Aktualny sprzedawca',
        value: '',
      },
      {
        name: 'contract_type',
        label: 'Rodzaj umowy',
        value: '',
      },
      {
        name: 'notice_period',
        label: 'Okres wypowiedzenia',
        value: '',
        type: 'date',
      },
      {
        name: 'contract_duration',
        label: 'Okres obowiązywania umowy',
        value: '',
        type: 'date',
      },
      {
        name: 'termination_date',
        label: 'Złożone wypowiedzenie',
        value: null,
        type: 'date',
      },
      {
        name: 'sale_start',
        label: 'Rozpoczęcie sprzedaży',
        value: null,
        type: 'date',
      },
      {
        name: 'sale_end',
        label: 'Koniec sprzedaży',
        value: null,
        type: 'date',
      },
    ]);

    let pointData = reactive({});

    const tags = ref([]);

    async function getTags() {
      const data = (await axios.get(urlBuilder('/tags/'))).data.results;
      tags.value = data.map((elem) => ({
        label: elem.name,
        value: elem.id,
      }));
    }

    const selectedTags = ref([]);

    function selectTags(event) {
      selectedTags.value = event;
    }

    function fillField() {
      formRows.value.forEach((elem) => {
        const element = elem;
        if (pointData[elem.name]) element.value = pointData[elem.name];
      });
      selectedTags.value = pointData.tags;
    }

    onMounted(async () => {
      await getTags();
      if (props.pointId) {
        pointData = (await axios.get(urlBuilder(`/points/${props.pointId}/`))).data;
        fillField();
      }
    });

    function buttonDetails() {
      let details = {};
      if (props.pointId) {
        details = {
          name: 'Zapisz zmiany',
          emitName: 'saveChanges',
        };
      } else {
        details = {
          name: 'Wyślij zgłoszenie',
          emitName: 'sendApplication',
        };
      }
      return details;
    }

    const buttonProps = buttonDetails();

    function preparePointData() {
      const payload = formRows.value.reduce(
        (obj, item) => ({ ...obj, [item.name]: item.value }), {},
      );
      payload.tags = selectedTags.value;
      return payload;
    }

    function emitShowMessage(message) {
      emit('show-message', message);
    }

    const errorMessage = ref([]);

    async function addNewPoint() {
      const payload = preparePointData();
      let response = [];
      try {
        response = await axios.post(urlBuilder('/points/'), payload, { silent: true });
        if (response.status === 201) {
          emitShowMessage('Punkt został dodany i oczekuje na weryfikację.');
        } else emitShowMessage('Wystąpił problem. Punkt nie został dodany');
        emit('close-modal-button');
      } catch (error) {
        if (error.response.status === 422) {
          errorMessage.value = error.response.data.errors;
        } else emitShowMessage('Wystąpił problem. Punkt nie został dodany');
      }
    }

    async function updatePoint() {
      const payload = preparePointData();
      const data = {
        pointId: props.pointId,
        message: '',
      };
      try {
        const response = await axios.put(urlBuilder(`/points/${props.pointId}/`), payload);
        if (response.status === 200) data.message = 'Zmiany w punkcie zostały zapisane';
        emit('close-edit', data);
      } catch (error) {
        if (error.response.status === 422) {
          errorMessage.value = error.response.data.errors;
        } else data.message = 'Wystąpił problem. Zmiany w punkcie nie zostały zmienione';
      }
    }

    function sendData() {
      if (!props.pointId) addNewPoint();
      else updatePoint();
    }

    return {
      buttonProps,
      errorMessage,
      formRows,
      selectTags,
      selectedTags,
      sendData,
      tags,
    };
  },
};
</script>

<style scoped>
</style>
