<template>
  <m-modal
    title="Zapisywanie danych"
    @closeModalButton="$emit('showMessage')"
  >
    <div class="d-flex justify-content-center align-items-center flex-column">
      <h4 class="mt-5 mb-5">
        {{ message }}
      </h4>
      <m-button
        class="btn-primary mb-3"
        name="Zatwierdź"
        emitName="close-message"
        @closeMessageButton="$emit('showMessage')"
      />
    </div>
  </m-modal>
</template>

<script>
import MModal from '@/components/common/MModal.vue';
import MButton from '@/components/common/MButton.vue';

export default {
  name: 'MessageModal',
  components: { MButton, MModal },
  props: {
    message: String,
  },
};
</script>

<style scoped>

</style>
