<template>
  <form>
    <div>
      <MInput
        class="mb-3"
        v-model="username"
        placeholder="Użytkownik"
        required
        type="text"
      />
      <p
        class="mt-3 text-danger small"
        v-if="msg.username"
      >
        {{ msg.username }}
      </p>
    </div>
    <div>
      <MInput
        v-model="password"
        placeholder="Hasło"
        required
        type="password"
      />
      <p
        class="mt-3 text-danger small"
        v-if="msg.password"
      >
        {{ msg.password }}
      </p>
    </div>
    <MButton
      class="btn btn-primary mt-3 w-100"
      emitName="login"
      name="Zaloguj"
      @loginButton="sendLoginRequest"
    />
    <MButton
      class="btn btn-text mt-3 mb-0 w-100"
      name="Nie pamiętam hasła"
      @click="router.push('/zmiana-hasla')"
    />
    <p v-if="statusCode === 401" class="mt-3 text-danger small">
      Nieprawidłowa nazwa użytkownika lub hasło.
    </p>
  </form>
</template>

<script>
import { reactive, ref } from 'vue';

import login from '@/plugins/auth';

import MInput from '@/components/common/MInput.vue';
import MButton from '@/components/common/MButton.vue';
import { useRouter } from 'vue-router';

export default {
  name: 'LoginForm',
  components: { MButton, MInput },
  setup() {
    const router = useRouter();
    const username = ref('');
    const password = ref('');
    const statusCode = ref();

    const msg = reactive({
      username: '',
      password: '',
    });

    function validationForm() {
      msg.username = '';
      msg.password = '';
      statusCode.value = null;

      if (!username.value) {
        msg.username = 'Pole "użytkownik" nie może być puste.';
      }
      if (!password.value) {
        msg.password += 'Pole "hasło" nie może być puste.';
      }
      return (!msg.username && !msg.password);
    }

    async function sendLoginRequest() {
      if (validationForm()) {
        const payload = {
          username: username.value,
          password: password.value,
        };
        statusCode.value = await login(payload);
      }
    }

    function enterButtonListener(e) {
      if (e.key === 'Enter') sendLoginRequest();
    }

    window.addEventListener('keydown', enterButtonListener);

    return {
      msg,
      password,
      sendLoginRequest,
      statusCode,
      username,
      router,
    };
  },
};
</script>

<style scoped>

</style>
