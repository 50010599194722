<template>
  <div
    @drop.prevent="drop"
    @dragover.prevent
    class="card dropdown-zone w-100"
  >
    <div class="card-body">
      <input
        @change="onChange"
        :accept="fileTypes.join(',')"
        id="addFile"
        ref="fileInput"
        type="file"
        class="dropdown-zone"
      />
      <label for="addFile" class="btn btn-secondary dropdown-zone">
        Prześlij pliki <i class="bi-arrow-bar-up" />
      </label>
      <div v-if="errorMessage" class="mt-3 text-danger small">
        {{ errorMessage }}
      </div>
      <ul
        class="small list-unstyled"
        v-if="fileName"
      >
        <li
          class="row mt-3 d-flex align-items-center"
          :key="fileName"
        >
          <div class="col-md-9">
          {{ fileName }}
          </div>
          <div class="col-md-3 d-flex justify-content-md-end">
            <m-button
              class="btn-danger"
              icon="bi-trash"
              @removeButton="remove"
              emitName="remove"
              name="Usuń"
            >
              Usuń
            </m-button>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import MButton from '@/components/common/MButton.vue';

export default {
  name: 'DragAndDropUpload',
  components: { MButton },
  props: {
    type: String,
  },

  setup(props, { emit }) {
    const fileName = ref('');
    const fileInput = ref(null);
    const fileTypes = [
      '.png', '.jpeg', '.pdf', '.jpg',
    ];
    const errorMessage = ref('');

    function resetInput() {
      fileInput.value.type = 'text';
      fileInput.value.type = 'file';
    }

    async function onChange() {
      const file = {
        value: { ...fileInput.value.files },
        type: props.type,
      };
      fileName.value = fileInput.value.files[0].name;
      emit(`${props.type}-file`, file);
      resetInput();
    }

    function remove() {
      fileName.value = '';
      const file = {
        value: '',
        type: props.type,
      };
      emit(`${props.type}-file`, file);
    }

    function drop(e) {
      const droppedFile = e.dataTransfer.files[0];
      if (fileTypes.includes(`.${droppedFile.name.split('.').pop()}`)) {
        fileInput.value.files = e.dataTransfer.files;
        if (fileInput.value.files.length > 1) {
          errorMessage.value = 'Można dodać tylko jeden plik';
        } else {
          errorMessage.value = '';
          onChange();
        }
      } else errorMessage.value = 'Format upuszczonego pliku jest niewłaściwy';
    }

    return {
      drop,
      errorMessage,
      fileName,
      fileInput,
      fileTypes,
      onChange,
      remove,
    };
  },
};
</script>

<style scoped>
input {
  opacity: 0;
  overflow: hidden;
  position: absolute;
}

.dropdown-zone {
  cursor: pointer;
}
</style>
