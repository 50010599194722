<template>
  <div
    class="d-flex"
    v-for="row in 4"
    :key="row"
  >
    <div
      class="d-flex col-4 p-1"
      v-for="column in 3"
      :key="column"

    >
      <div
        :class="monthNumber(row, column) === selectedMonth ?
        'btn-primary' :
        'btn-outline-secondary'"
        class="btn w-100"
        @click="selectMonth(row, column)"
      >
        {{ monthName(row, column) }}
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';
import dayjs from 'dayjs';

require('dayjs/locale/pl');

export default {
  name: 'MMonthPicker',
  emits: ['select-month'],
  props: {
    name: {
      default: null,
      type: String,
    },
    month: {
      default: null,
      type: Number,
    },
  },

  setup(props, { emit }) {
    function monthNumber(row, column) {
      return (row - 1) * 3 + column - 1;
    }

    function monthName(row, column) {
      const number = monthNumber(row, column);
      return dayjs().month(number).locale('pl').format('MMMM');
    }

    const selectedMonth = ref(props.month ? props.month - 1 : null);
    const isSelected = ref(false);

    function emitValue() {
      const value = {
        name: props.name,
        month: selectedMonth.value,
        isSelectedMonth: isSelected.value,
      };
      emit('select-month', value);
    }

    function selectMonth(row, column) {
      const number = monthNumber(row, column);
      if (number === selectedMonth.value) {
        selectedMonth.value = null;
        isSelected.value = false;
      } else {
        selectedMonth.value = number;
        isSelected.value = true;
      }
      emitValue();
    }

    return {
      monthName,
      monthNumber,
      selectedMonth,
      selectMonth,
    };
  },
};
</script>

<style scoped>
</style>
