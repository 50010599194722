<template>
  <div class="row d-flex flex-grow-1 align-items-center mb-3">
    <div class="col-sm-3" v-if="label">
      <label class="fw-bold">{{ label }}</label>
    </div>
    <div :class="label ? 'col-sm-9' : 'col-sm-12'">
      <drag-and-drop-upload
        :type="type"
        v-bind="$attrs"
      />
    </div>
  </div>
</template>

<script>
import DragAndDropUpload from '@/components/upload/DragAndDropUpload.vue';

export default {
  name: 'UploadFileBox',
  components: { DragAndDropUpload },
  props: {
    label: String,
    type: String,
  },
};
</script>

<style scoped>

</style>
