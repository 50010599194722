<template>
  <div class="modal-open">
    <div class="modal fade show"
         style="display: block; padding-left: 0"
         tabindex="-1"
    >
      <div class="modal-dialog modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title h5">
              {{ title }}
            </h5>
            <m-button
              class="btn-close"
              emitName="close-modal"
              name=""
              v-bind="$attrs"
            />
          </div>
          <div class="modal-body">
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show" @click="$emit('close-modal')"></div>
</template>

<script>
import MButton from '@/components/common/MButton.vue';

export default {
  components: { MButton },
  props: {
    title: String,
  },
  name: 'MModal',
};
</script>

<style scoped>
</style>
