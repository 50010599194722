<template>
  <div>
    <m-button
      @click="subtractYear"
      icon="bi-caret-left-fill"
    />
      <span>
        {{ selectedYear }}
      </span>
    <m-button
      @click="addYear"
      icon="bi-caret-right-fill"
    />
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import dayjs from 'dayjs';

import MButton from '@/components/common/MButton.vue';

export default {
  name: 'MYearPicker',
  components: { MButton },
  props: {
    name: {
      type: String,
      default: null,
    },
    year: {
      type: String,
      default: null,
    },
  },

  setup(props, { emit }) {
    const selectedYear = ref(props.year ? props.year : dayjs().format('YYYY'));

    function emitValue() {
      const value = {
        name: props.name,
        year: selectedYear.value,
      };
      emit('select-year', value);
    }

    function addYear() {
      selectedYear.value = dayjs(selectedYear.value).add(1, 'year').format('YYYY');
      emitValue();
    }

    function subtractYear() {
      selectedYear.value = dayjs(selectedYear.value).subtract(1, 'year').format('YYYY');
      emitValue();
    }

    onMounted(() => emitValue());

    return {
      addYear,
      selectedYear,
      subtractYear,
    };
  },
};
</script>

<style scoped>

</style>
