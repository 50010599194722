import axios from 'axios';
import store from '@/store';
import router from '@/router';
import { refresh } from '@/plugins/helpers';

function axiosRequest() {
  axios.interceptors.request.use((config) => {
    const access = store.getters.accessToken;
    const newConfig = config;
    if (access) {
      newConfig.headers.Authorization = `Token ${access}`;
    }
    return newConfig;
  }, (err) => Promise.reject(err));
}

function axiosResponse() {
  axios.interceptors.response.use((response) => response,
    async (error) => {
      const originalRequest = error.config;
      if (error.response.status === 401 && error.response.data.code === 'password_expired') {
        await router.push('/password-expired');
      }
      if (error.response.status === 401 && error.response.data.code === 'user_locked') {
        await router.push('/user-locked');
      }
      if (error.response.status === 429) {
        await router.push('/throttle');
      }
      if (error.response.status === 401 && originalRequest.url.includes('/token/refresh/')) {
        store.commit('deleteAccessToken');
        store.commit('deleteRefreshToken');
        await router.push('/');
      }
      if (store.getters.refreshToken && error.response.status === 401 && !originalRequest.retry) {
        await refresh();
        originalRequest.retry = true;
        axios.defaults.headers.common.Authorization = `Token ${store.getters.accessToken}`;
        return axios(originalRequest);
      }
      return Promise.reject(error);
    });
}

export default function setup() {
  axiosRequest();
  axiosResponse();
}
