<template>
  <div class="card">
    <div class="card-body">
      <div
        class="d-flex my-4 justify-content-between align-items-center"
        v-for="source in sources"
        :key="source.name"
      >
        <h3>{{ source.name }}</h3>
        <m-button
          class="btn btn-secondary btn-lg btn-block w-25"
          emitName="download"
          name="Pobierz"
          icon="bi-download"
          :link="source.src"
          :fileName="source.name"
        />
      </div>
    </div>
  </div>
</template>

<script>
import MButton from '@/components/common/MButton.vue';

export default {
  name: 'DownloadFilesBox',
  components: { MButton },
  setup() {
    const sources = [
      {
        name: 'Wzór wypowiedzenia',
        src: require('@/assets/files/Wzór wypowiedzenia umowy kompleksowej sprzedazy energii elektrycznej.pdf'),
      },
      {
        name: 'Wzór umowy przyłączeniowej',
        src: require('@/assets/files/Wzór umowy przyłączeniowej.pdf'),
      },
      {
        name: 'Wzór reklamacji',
        src: require('@/assets/files/Wzór reklamacji.pdf'),
      },
      {
        name: 'Cennik taryfowy obecnego sprzedawcy',
        src: require('@/assets/files/Taryfa dla energii elektrycznej dla Klientów z grup taryfowych A, B, C i R.pdf'),
      },
      {
        name: 'Wzór aneksu dodającego/usuwającego punkty PPE',
        src: require('@/assets/files/Wzór - Aneks dołączający_usuwający PPE.pdf'),
      },
    ];

    return {
      sources,
    };
  },
};
</script>

<style scoped>

</style>
