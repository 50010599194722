<template>
  <div class="starry-bg">
  <div class="d-flex align-items-center justify-content-center vh-100 container">
    <div class="card d-flex box border-dark">
      <div class="d-flex w-100 logo mx-0 mb-3 pt-4 p-3 align-content-center justify-content-center">
        <img class="w-50" :src="require('@/assets/logo.png')" alt="Voltra">
      </div>
      <div class="card-body pb-5 px-5 flex">
        <h5 class="card-title text-center mb-4">Przypomnienie hasła</h5>
        <label class="mb-3 text-center">
          Podaj adres e-mail, na który ma zostać wysłany link do zmiany hasła.
        </label>
        <div class="row">
        <MInput
            class="w-100 mx-0 px-0"
            v-model="email"
            placeholder="e-mail"
            required
            type="email"
          />
        </div>
        <p
          class="w-100 text-danger small"
          v-if="msg.email"
        >
          {{ msg.email }}
        </p>
        <MButton
          class="btn btn-primary mt-3 w-100"
          emitName="sendEmail"
          name="Wyślij"
          @sendEmailButton="sendChangeRequestRequest"
        />
      </div>
    </div>
  </div>
  </div>
</template>

<script>
import { reactive, ref } from 'vue';

import MInput from '@/components/common/MInput.vue';
import MButton from '@/components/common/MButton.vue';
import axios from 'axios';
import { urlBuilder } from '@/plugins/helpers';

export default {
  name: 'ChangePasswordEmailForm',
  components: { MButton, MInput },
  setup(props, { emit }) {
    const email = ref('');

    const msg = reactive({
      email: '',
    });

    function validationForm() {
      msg.email = '';

      if (!email.value) {
        msg.email = 'Pole "e-mail" nie może być puste.';
      } else {
        const regex = new RegExp(
          '^(([^<>()\\[\\]\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\.,;:\\s@"]+)*)|'
          + '(".+"))@(([^<>()\\[\\]\\.,;:\\s@"]+\\.)+[^<>()\\[\\]\\.,;:\\s@"]{2,})$',
        );
        msg.email = regex.test(email.value) ? '' : 'Adres e-mail ma nieprawidłowy format.';
      }
      return !msg.email;
    }

    function sendEmail(payload) {
      axios.post(urlBuilder('/reset_password/'), payload);
      emit('email-sent');
    }

    async function sendChangeRequestRequest() {
      if (validationForm()) {
        const payload = {
          email: email.value,
        };
        await sendEmail(payload);
      }
    }

    function enterButtonListener(e) {
      if (e.key === 'Enter') sendChangeRequestRequest();
    }

    window.addEventListener('keydown', enterButtonListener);

    return {
      email,
      msg,
      sendChangeRequestRequest,
    };
  },
};
</script>

<style scoped>
.box {
  min-width: 300px;
  width: 25rem;
  overflow: hidden;
  box-shadow:
    0 1.8px 2.2px rgba(0, 0, 0, 0.034),
    0 4.7px 5.3px rgba(0, 0, 0, 0.048),
    0 8.5px 10px rgba(0, 0, 0, 0.06),
    0 16.3px 17.9px rgba(0, 0, 0, 0.072);
}
.logo {
  background-color: #2f2f2f;
}

.starry-bg {
  background-image: url("~@/assets/header_bg.jpeg");
  background-size: cover;
}
</style>
