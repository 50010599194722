<template>
  <div class="row d-flex flex-grow-1 align-items-center">
    <div class="col-sm-3" v-if="label">
      <label class="fw-bold">{{ label }}</label>
    </div>
    <div :class="label ? 'col-sm-9' : 'col-sm-12'">
      <multiselect
        v-model="value"
        v-bind="$attrs"
        :options="data"
        mode="tags"
        :searchable="true"
        :createTag="true"
      />
    </div>
  </div>
</template>

<script>
import { ref } from 'vue';

import Multiselect from '@vueform/multiselect';

export default {
  name: 'MMultiselect',
  components: {
    Multiselect,
  },
  props: {
    data: {
      default: null,
      type: Array,
    },
    label: String,
  },
  setup() {
    const value = ref([]);

    return {
      value,
    };
  },
};
</script>

<style scoped>
@import '../../../node_modules/@vueform/multiselect/themes/default.css';

</style>
