<template>
  <div class="card">
    <div class="card-body">
      <div
        class="row mb-3"
        v-for="price in prices"
        :key="price.label"
      >
        <ProfitCell :price="price"/>
      </div>
    </div>
  </div>
</template>

<script>
import ProfitCell from '@/components/cell/ProfitCell.vue';

export default {
  name: 'ProfitBox',
  components: { ProfitCell },
  setup() {
    const prices = [
      {
        label: 'Enea',
        logo: require('@/assets/logos/enea.png'),
        price: '2,3140',
        charge: '28',
      },
      {
        label: 'Energa',
        logo: require('@/assets/logos/energea.png'),
        price: '2,2990',
        charge: '30',
      },
      {
        label: 'e-on',
        logo: require('@/assets/logos/eon.png'),
        price: '3,1145',
        charge: '39,21',
      },
      {
        label: 'PGE',
        logo: require('@/assets/logos/pge.png'),
        price: '2,1000',
        charge: '35',
      },
      {
        label: 'TAURON',
        logo: require('@/assets/logos/tauron.png'),
        price: '2,3860',
        charge: '35',
      },
    ];

    return {
      prices,
    };
  },
};
</script>

<style scoped>
</style>
