<template>
  <div class="border-top pt-3 mt-3 d-flex flex-column">
    <div class="d-flex mb-0">
      <p class="me-2" v-if="dateFrom">Od: {{ dateFrom }}</p>
      <p v-if="dateTo">Do: {{ dateTo }}</p>
    </div>
    <div class="d-flex">
      <div class="me-3">
        <h4>Koszt: {{ data.cost }} brutto</h4>
      </div>
      <div class="me-3">
        <h4>Zużycie: {{ data.usage }} kWh</h4>
      </div>
    </div>

  </div>
</template>

<script>
import { computed } from 'vue';
import store from '@/store';
import dayjs from 'dayjs';

export default {
  name: 'TotalCost',
  props: {
    data: Object,
  },
  setup() {
    const dateFrom = computed(() => {
      const date = store.getters.dateFromFilter;
      if (date) {
        return dayjs(date).locale('pl').format('MMMM YYYY');
      }
      return null;
    });
    const dateTo = computed(() => {
      const date = store.getters.dateToFilter;
      if (date) {
        return dayjs(date).locale('pl').format('MMMM YYYY');
      }
      return null;
    });

    return {
      dateFrom,
      dateTo,
    };
  },
};
</script>

<style scoped>
</style>
