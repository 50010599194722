<script setup>
import { defineProps } from 'vue';

defineProps({
  msg: {
    type: String,
    required: true,
  },
});
const messages = {
  throttle: 'Wykonano zbyt wiele zapytań w krótkim czasie. Spróbuj ponownie za minutę.',
  'user-locked': 'Konto zostało zablokowane. Skontaktuj się z administratorem.',
  'password-expired':
    'Hasło wygasło. Na adres e-mail przypisany do konta został wysłany link do zmiany hasła',
};

</script>

<template>
  <div>
    {{ messages[msg] }}
  </div>
</template>

<style scoped>

</style>
