<template>
  <td>{{cost.costs}}</td>
  <td>{{cost.usage}}</td>
  <td>{{cost.date}}</td>
</template>

<script>
export default {
  name: 'CostsTableRow',
  props: {
    cost: Object,
  },
};
</script>

<style scoped>

</style>
