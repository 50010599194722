import axios from 'axios';
import store from '@/store/index';

import pointLabels from '@/consts/index';

export function urlBuilder(path, params = null) {
  const apiUrl = process.env.VUE_APP_API_URL;
  let url = `${apiUrl}${path}`;
  if (params !== null && Object.keys(params).length !== 0) {
    url += '?';
    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of Object.entries(params)) {
      if (url.slice(-1) !== '?') url += '&';
      url += `${key}=${value}`;
    }
  }
  return `${url.replace(/\/?(\?|#|$)/, '/$1')}`;
}

export async function refresh() {
  let response = {};

  try {
    response = await axios.post(urlBuilder('/token/refresh/'), { refresh: store.getters.refreshToken });
  } catch (error) {
    store.commit('deleteAccessToken');
    store.commit('deleteRefreshToken');
    store.dispatch('restartIsSuperuser');
    return error.response.status;
  }
  if (response.status === 200) {
    store.commit('saveAccessToken', response.data.access);
  }
  return response.status;
}

export function parsePointData(pointData, divisions, tags) {
  const labels = pointLabels;
  const tagsObject = tags.reduce((obj, item) => ({ ...obj, [item.id]: item.name }), {});

  return Object.entries(pointData).map((elem) => {
    let value;
    if (elem[0] === 'tags') {
      const tagList = elem[1].map((tagId) => tagsObject[tagId]);
      value = tagList.join(', ');
    } else if (elem[0] === 'division') {
      value = divisions.filter((division) => division.id === elem[1])[0].name;
      // eslint-disable-next-line prefer-destructuring
    } else value = elem[1];
    return {
      value,
      label: labels[elem[0]],
    };
  });
}
