<template>
  <table class="table table-striped" v-if="costs.length">
    <thead>
    <tr>
      <th>
        Koszt (brutto)
      </th>
      <th>
        Zużycie (kWh)
      </th>
      <th>
        Data
      </th>
    </tr>
    </thead>
    <tbody>
      <tr
        v-for="cost in costs"
        :key="cost.id"
      >
        <costs-table-row
          :cost="cost"
        />
      </tr>
    </tbody>
  </table>
</template>

<script>
import CostsTableRow from '@/components/table/rows/CostsTableRow.vue';

export default {
  name: 'CostsTable',
  components: { CostsTableRow },
  props: {
    costs: Array,
  },
};
</script>

<style scoped>

</style>
