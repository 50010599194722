<template>
  <div class="row d-flex flex-grow-1 align-items-center">
    <div class="col-sm-3" v-if="label">
      <label class="fw-bold">{{ label }}</label>
    </div>
    <div :class="label ? 'col-sm-9' : 'col-sm-12'">
      <input
        class="form-control"
        :placeholder="placeholder"
        :required="required"
        :type="type"
        :autocomplete="type === 'password' ? 'on' : ''"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @focus="$emit('focus')"
      >
    </div>
  </div>
</template>

<script>
export default {
  name: 'MInput',
  props: {
    label: {
      default: '',
      type: String,
    },
    placeholder: {
      default: '',
      type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    modelValue: String,
  },
};
</script>

<style scoped>
</style>
