const pointLabels = {
  division: 'Dywizja',
  name: 'Nazwa punktu poboru',
  city: 'Miasto punktu',
  post: 'Poczta punktu',
  street: 'Ulica punktu',
  street_number: 'Numer miejsca/domu punktu',
  zip_code: 'Kod pocztowy punktu',
  ppe_number: 'Numer PPE',
  osd_number: 'Numer ewidencyjny OSD',
  counter_number: 'Numer licznika',
  tariff: 'Obecna taryfa',
  power: 'Moc umowna',
  osd_next: 'Numer lokalnego OSD',
  seller: 'Aktualny sprzedawca',
  contract_type: 'Rodzaj umowy',
  notice_period: 'Okres wypowiedzenia',
  contract_duration: 'Okres obowiązywania umowy',
  termination_date: 'Złożone wypowiedzenie',
  sale_start: 'Rozpoczęcie sprzedaży',
  sale_end: 'Koniec sprzedaży',
  tags: 'Tagi',
};

export const fileTypes = [
  {
    name: 'invoice',
    id: 1,
  },
  {
    name: 'agreement',
    id: 2,
  },
  {
    name: 'comments',
    id: 3,
  },

];

export default pointLabels;
