<template>
  <div
    @click="$emit(`chip`, !value)"
    class="m-chip"
    :class="value ? `badge rounded-pill ${color}` : 'badge rounded-pill bg-light text-dark'"
  >
    {{ label }}
  </div>
</template>

<script>
export default {
  name: 'MChip',
  props: {
    label: String,
    value: {
      default: true,
      type: Boolean,
    },
    color: {
      default: 'bg-primary',
      type: String,
    },
  },
};
</script>

<style scoped>
  .m-chip {
    cursor: pointer;
    margin-right: 4px;
    user-select: none;
  }
</style>
