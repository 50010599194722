<template>
  <div class="card">
    <div class="card-header">
      <ul class="nav nav-tabs card-header-tabs">
        <li class="nav-item">
          <a class="nav-link active">Dane sprzedażowe</a>
        </li>
        <li class="nav-item">
          <a class="nav-link disabled">Dane dystrybucyjne</a>
        </li>
      </ul>
    </div>
    <div class="card-body d-flex flex-column">
      <div
        class="row mb-2"
        v-for="data in pointDataList"
        :key="data.label"
      >
        <div
          v-if="data.label"
          class="col-sm-3"
        >
          <b>{{ data.label }}</b>
        </div>
        <div
          v-if="data.label"
          class="col-sm-9"
        >
          <span>{{ data.value }}</span>
        </div>
      </div>
      <h5 class="mt-4 pt-4 border-top">Punkty kosztowe</h5>
      <costs-table
        :costs="values.costs"
      />
      <distribution-upload-files
        @agreementFile="addFile"
        @invoiceFile="addFile"
        @commentsFile="addFile"
      />
      <m-button
        class="btn btn-lg btn-primary align-self-end"
        name="Wyślij pliki"
        emitName="send-files"
        icon="bi-arrow-bar-up"
        @sendFilesButton="sendFiles"
      />
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import {
  onMounted, reactive, ref, watch,
} from 'vue';

import { parsePointData, urlBuilder } from '@/plugins/helpers';

import { fileTypes } from '@/consts/index';

import DistributionUploadFiles from '@/layouts/DistributionUploadFiles.vue';
import MButton from '@/components/common/MButton.vue';
import CostsTable from '@/components/table/CostsTable.vue';
import store from '@/store';

export default {
  name: 'PointDetails',
  props: {
    pointId: Number,
    filters: Object,
  },
  components: { CostsTable, MButton, DistributionUploadFiles },
  setup(props, { emit }) {
    let pointData = reactive({});

    const pointDataList = ref([]);

    let divisions = reactive({});

    let tags = reactive({});

    async function getDivisions() {
      divisions = (await axios.get(urlBuilder('/divisions/'))).data.results;
    }

    async function getTags() {
      tags = (await axios.get(urlBuilder('/tags/'))).data.results;
    }

    const values = reactive({
      costs: [],
    });

    async function getPointDetails() {
      const filters = {};
      if (props.filters.costs_date_after) filters.costs_date_after = props.filters.costs_date_after;
      if (props.filters.costs_date_before) {
        filters.costs_date_before = props.filters.costs_date_before;
      }
      pointData = (await axios.get(urlBuilder(`/points/${props.pointId}`, filters))).data;
      delete pointData.id;
      values.costs = pointData.costs;
      pointDataList.value = parsePointData(pointData, divisions, tags);
    }

    onMounted(async () => {
      await getDivisions();
      await getTags();
      await getPointDetails();
    });

    watch(() => store.getters.dateFromFilter || store.getters.dateToFilter, async () => {
      await getPointDetails();
    });

    const files = reactive({
      agreement: '',
      invoice: '',
      comments: '',
    });

    function addFile(e) {
      files[e.type] = { ...e.value };
    }

    function sendFiles() {
      const types = fileTypes;
      types.forEach(async (type) => {
        if (files[type.name]) {
          const formData = new FormData();
          formData.append('file', files[type.name][0]);
          formData.append('file_type', type.id);
          formData.append('point_id', props.pointId);
          const response = await axios.post(urlBuilder('/files/'), formData, {
            headers: {
              'content-type': 'multipart/form-data',
              'content-disposition': 'attachment',
              filename: files[type.name][0].name,
            },
          });
          emit('close-details');
          if (response.status === 201) {
            emit('message', 'Plik został zapisany na serwerze');
          } else {
            emit('message', 'Wystąpił błąd. Plik nie został zapisany na serwerze');
          }
        }
      });
    }

    return {
      addFile,
      divisions,
      pointDataList,
      sendFiles,
      tags,
      values,
    };
  },
};
</script>

<style scoped>
</style>
