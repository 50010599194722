<script>
export default {
  name: 'ProfitCell',
  props: {
    price: Object,
  },
};
</script>

<template>
  <div class="d-flex">
    <div class="col-4 m-auto">
      <img class="w-50" :src="price.logo" :alt="price.label">
    </div>
    <div class="col-2 m-auto">
      <p class="text-center">
        Cena<br>(całodobowo)
      </p>
      <p class="text-center">
        <b>
          {{ price.price }}
        </b>
        zł/kWh
      </p>
    </div>
    <div class="col-2 m-auto">
      <p class="text-center">Opłata<br>handlowa</p>
      <p class="text-center">
        <b>
          {{ price.charge }}
        </b>
        zł
      </p>
    </div>
  </div>
</template>

<style scoped>

</style>
